angular.module('footer', [])
    .directive('footerModule', ['$state', 'utilsService',
        function ($state, utilsService) {
            return {
                restrict: 'AE',
                templateUrl: 'app/shared/footer/footerView.html',
                transclude: true,
                scope: {},
                link: function (scope, element, attrs) {
                    scope.Utils = utilsService;
                }
            }
        }]);
