/*
 * WordPress Authentication Service
 *
 * wpAuthService is used by sessionService to be able to check the authentication.
 * It can't be used by a module injecting sessionService
 *
 */

angular.module('api.wp.auth', [])
    .factory('wpAuthService', ['$resource', 'config', '$q',
        function ($resource, config, $q) {

            var wpAuthResource = function (header, endpoint) {
                return new $resource(config.URL_API + config.WP_API + endpoint, {},
                    {
                        'post': {
                            method: 'POST',
                            params: {},
                            isArray: false,
                            headers: header || {}
                        }
                    });
            }; 

            return {

                // WP Token Authentication
                getAuth: function (userInfos, apiToken) {
                    var deferred = $q.defer(); 
                    var header = {
                        'username': userInfos.username,
                        'password': userInfos.password,
                        'Authorization': apiToken
                    };

                    return wpAuthResource(header, '/auth').post().$promise
                        .then(function (success) {
                            // console.log(success);
                            if (angular.isDefined(success.data) && success.data.status == 403) {
                                deferred.reject('403');
                            } else {
                                deferred.resolve(success);
                            }
                            return deferred.promise;
                        });
                },

                checkAuth: function (token, apiToken) {
                    return wpAuthResource({
                        'x-access-token': token,
                        'Authorization': apiToken
                    }, '/auth/validate').post().$promise;
                }
            };
        }
    ]);
