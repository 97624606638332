angular.module('service.redirection', [])
	.factory('redirectionService', ['$state', '$cookies', '$rootScope',
		function ($state, $cookies, $rootScope) {

			return {
				goAuthPage: function (redirectTarget, message) {
					var redirect = {
						page: redirectTarget,
						msg: message
					};
					$cookies.putObject('redirectService', redirect);
					console.log($cookies.getObject('redirectService'));
					$state.go('auth');
				},
				isRedirection: function () {
					return $cookies.getObject('redirectService') || false;
				},
				redirect: function () {
					var target = $cookies.getObject('redirectService');
					console.log(target);

					if(angular.isDefined(target) && target.page != '') {
						$state.go(target.page);
					}
				}

			}
		}
	]);
