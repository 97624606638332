angular.module('api.wp', [])
    .factory('wpService', ['$resource', 'config', '$q', '$http', 'sessionService',
        function ($resource, config, $q, $http, sessionService) {

            var wpStorage = [];

            var wpResource = function (header, endpoint, params, isArray) {
                if(isArray === undefined) {
                    isArray = true;
                }
                endpoint = endpoint || '';
                return new $resource(config.URL_API + config.WP_API + endpoint, {},
                    {
                        'get': {
                            method: 'GET',
                            params: params || {},
                            isArray: isArray,
                            headers: header || {}
                        }
                    });
            };

            function getResult(pageName, section, params, data) {

                var result = data[section];                

                if(result) {
                    if(params && params.slug) {
                        var tmp = result.find(function(elem) {
                            return elem.slug == params.slug;
                        });
                        result = tmp;
                    }
                    if(params && params.id) {
                        var tmp = result.find(function(elem) {
                            return elem.id == params.id;
                        });
                        result = tmp;
                    }
                    if(params && params.categories) {
                        var tmp = result.filter(function(elem) {
                            return elem.categories.find(function(n) {
                                return params.categories.indexOf(n) !== -1;
                            });
                            
                        });
                        result = tmp;                    
                    }

                } 
                return result;
            };
            
            function getData(pageName, wpResource, endpoint, section, params, isArray) {
                
                if(isArray === undefined) {
                    isArray = true;
                }
                var deferred = $q.defer();
                // var lang = config.DEFAULT_LANG;
                var lang = sessionService.getLang();

                if(wpStorage[lang] === undefined) wpStorage[lang] = [];

                if(wpStorage[lang][pageName] === undefined) {
                    wpStorage[lang][pageName] = null;
                }
                if (wpStorage[lang][pageName]) {
                    var toReturn = wpStorage[lang][pageName];
                    if(section !== null) {
                        toReturn = getResult(pageName, section, params, wpStorage[lang][pageName]);
                    }
                    if(toReturn) {
                        deferred.resolve(toReturn);
                    } else { 
                        deferred.reject(404);
                    }
                } else {
                    wpResource({Authorization: sessionService.getApiToken()}, endpoint, {}, isArray).get({'lang': lang}).$promise
                        .then(function (success) {
                            // console.log(params, section);

                            wpStorage[lang][pageName] = success;
                            var toReturn = wpStorage[lang][pageName];

                            if(section !== null) {
                                toReturn = getResult(pageName, section, params, success);
                            }

                            if(toReturn) {
                                deferred.resolve(toReturn);
                            } else {
                                deferred.reject(404);
                            }
                        }, function (error) {
                            deferred.reject(error);
                        });
                }
                return deferred.promise;
            };

            return {

                /****************
                 * API WP POSTS *
                 ****************/

                getPosts: function (params, section) {
                    return getData('posts', wpResource, config.WP_POSTS, section || null, params, false);
                },
                getPost: function (params, section) {
                    return getData('posts', wpResource, config.WP_POSTS, section || null, params, false);
                },



                /****************
                 * API WP PAGES *
                 ****************/

               /* WP PAGES : Press */
                getPages: function (params, section) {
                    return getData('pages', wpResource, config.WP_PAGES, section || null, params);
                },
                getPageAbout: function () {
                    return this.getPages({'slug': 'about'});
                },
                getPageContact: function () {
                    return this.getPages({'slug': 'contact'});
                },


                /*****************
                 * WP CATEGORIES *
                 *****************/
                getCategories: function (params, section) {
                    return getData('categories', wpResource, config.WP_CATEGORIES, section || null, params);
                }


            }
        }
    ]);
