angular.module('home', [])
    .controller('homeController', ['$scope', '$window', '$stateParams', '$state', '$timeout', '$translator', '$rootScope', 'debounce', 'utilsService', 'wpService', 'sessionService',
        function ($scope, $window, $stateParams, $state, $timeout, $translator, $rootScope, debounce, utilsService, wpService, sessionService) {
            var msnry;
            var totalPosts;
            var postsLoaded = 0;
            var win = angular.element($window);

            $scope.Utils = utilsService;
            $scope.currentLang = sessionService.getLang();

            $scope.defaultCoverLand = './assets/img/defaultCoverLand.jpg';
            $scope.defaultCoverPort = './assets/img/defaultCoverPort.jpg';
            $scope.homeHeader = './assets/img/home_header_bis.jpg';

            /**
             * Listeners - Window Resize + Images loading
             */
            var masonryLayoutUpdate = debounce(function () {
                if (msnry) {
                    msnry.layout();
                }
            }, 200);

            angular.element($window).on('resize', masonryLayoutUpdate);
            $rootScope.$on('imageLoader', function (event, args) {
                console.log('img loaded');
                postsLoaded++;
                if (msnry) {
                    msnry.layout();
                }
                if (postsLoaded > totalPosts / 2) {}
                if (postsLoaded == totalPosts) {
                    $timeout(masonryLayoutUpdate, 500);
                }
            })

            $scope.detectEvent = function (slug) {
                $rootScope.$broadcast('startAnim');
                $timeout(function () {
                    if ($stateParams.category) {
                        $scope.Utils.go('ProjectByCategory', {
                            'category': $stateParams.category,
                            'slug': slug
                        })
                    } else {
                        $scope.Utils.go('project', {
                            'slug': slug
                        })
                    }
                }, 700);
            }

            function homeMenuFadeIn(menuDisplayPosition) {
                var menu = $('menu-module');
                var scroll;
                
                menu.removeClass('menu_home_hidden');
                win.resize(function () {
                    scroll = win.scrollTop();
                })
                $timeout(function () {
                    if ($state.is("home") && !menu.hasClass('home_menu_hidden')) {
                        menu.addClass('home_menu_hidden');
                    }
                    win.scroll(function () {
                        scroll = win.scrollTop();
                        if ((scroll + 180) > menuDisplayPosition) {
                            menu.removeClass('home_menu_hidden');
                        } else {
                            menu.addClass('home_menu_hidden');
                        }
                    })
                }, 3400)
            }

            function init() {
                if ($state.is("home")) {
                    homeMenuFadeIn(win.height());
                }
                $scope.Utils.checkDevice();
                wpService.getPosts()
                .then(function (posts) {
                    console.log(posts);
                    var category = $stateParams.category ? $translator.translate('category_' + $stateParams.category) : $translator.translate('category_selected');        
                    console.log(category);
                    $scope.projects = posts[category];
                        totalPosts = $scope.projects.length;
                        $timeout(function () {
                            msnry = new Masonry('.grid', {
                                itemSelector: '.grid__elem',
                                percentPosition: true,
                                gutter: 0,
                                stagger: 30,
                                transitionDuration: 0,
                                resize: false
                            });
                        });
                    }, function (error) {
                        console.log(error);
                    });
                $scope.isHome = function () {
                    return $state.is("home");
                }
            };
            init();
        }
    ]);