/*
 * Session Service
 *
 * SessionService is triggered at each full refresh to create the session
 * It requires the Node Api Token to be able to access the api, and stores it
 * Then, if the customer logs in, is will store his data and WP's Token
 *
 */

angular.module('service.storage', [])
	.service('storeService', ['$localStorage', '$q',
		function ($localStorage, $q) {

			// ***********
			// storeSession only stores a given session to localStorage
			// And retrieves it with ots key
			// ***********

			return {

				get: function (key) {
					var deferred = $q.defer();

					var time = new Date;
					time = time.getTime();

					if ($localStorage[key]) {
						if(time < $localStorage[key].expiration) {
							var storage = angular.fromJson($localStorage[key]);

							deferred.resolve(storage.value);
						} else {
							deferred.reject({
								code: 401,
								msg: 'Expired'
							});
						}
					} else {
						deferred.reject({
							code: 404,
							msg: 'Not found'
						});
					}
					return deferred.promise;
				},

				set: function (key, val) {
					if (val === undefined) {
						delete $localStorage[key];
					} else {
						var time = new Date();
						var expiration = time.getTime() + (2 * 60 * 60 * 1000); //2 hours

						$localStorage[key] = {
							value: val,
							expiration: expiration
						};
					}
					return $localStorage[key];
				},

				remove: function (key) {
					delete $localStorage[key];
				}
			}
		}
	]);
