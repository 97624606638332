angular.module('loader', [])
    .directive('loader', ['$rootScope', 'utilsService', '$timeout',
        function ($rootScope, utilsService, $timeout) {
            return {
                restrict: 'AE',
                templateUrl: 'app/shared/loading/loadingView.html',
                scope: {
                    nbrImageTotal: '=',
                    callback: '&'
                },
                link: function (scope, element, attrs) {
                    var cpt = 0;
                    scope.imageLoaded = false;

                    $('body').css({
                        'overflow-y': 'hidden'
                    });

                    $rootScope.$on('imageLoader', function (event, data) {
                        cpt++;
                        console.log('loader', cpt, scope.nbrImageTotal);
                        if (cpt == Math.round(scope.nbrImageTotal)) {
                            cpt = 0;
                            // $timeout(function() {

                                console.log('page loaded');
                                scope.imageLoaded = true;
                                $('body').css({
                                    'overflow-y': 'visible'
                                });
                                $('.wrapper.loading').addClass('animate_out');
                                $timeout(function() {
                                    $('.wrapper.loading').css('display', 'none');
                                    // deregisterListener();
                                }, 600);
                                scope.callback;

                            // }, 0);



                        } else {
                            scope.imageLoaded = false;
                        }
                    });
                }
            }
        }]);
