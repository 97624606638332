/**
 * Created by RSW on 18/05/2017.
 */

angular.module('service.auth0', [])
    .factory('Auth0', ['$resource', 'config', '$q',
        function ($resource, config, $q) {

            // var NON_INTERACTIVE_CLIENT_ID = 'eXeb51rf7GwaGGC1YHUMMcxes0BhhZ2W';
            // var NON_INTERACTIVE_CLIENT_SECRET = '_tBUIYt-xACgl6NVnoZj0b3NWeM1AjcyDtGduf9qU28brKUe9rXycD0JzyShboK1';

            //TEST
            var NON_INTERACTIVE_CLIENT_ID = 'wWtsSdO2VG3IiPGw73JkU8uRsF9g710P';
            var NON_INTERACTIVE_CLIENT_SECRET = 'Z7md72Dbg2KSxHcucvzLRJmllNHY0JCQJdilJLOCZ2nSYxL9j1QkZQ2Rxb-JNItj';

            // Next, we’ll define an object that we’ll use to exchange our credentials for an access token.
            var authData = {
                client_id: NON_INTERACTIVE_CLIENT_ID,
                client_secret: NON_INTERACTIVE_CLIENT_SECRET,
                grant_type: 'client_credentials',
                audience: 'https://regularswitch.com'
            };
            var accessTokenResource = function() {
                return new $resource('https://regularswitch.auth0.com/oauth/token');
            };


            return {

                getAccessToken: function() {
                    return accessTokenResource().save(authData).$promise;
                }
            };
        }]);
