angular.module('service.utils', [])
    .factory('utilsService', ['$state', '$window', '$sce', function ($state, $window, $sce) {

        return {
            getElementByClass: function (className) {
                var element = document.getElementsByClassName(className);
                var result = angular.element(element);
                return result;
            },
            go: function (page, params) {
                console.log(page, params);                
                $state.go(page, params);
            },
            detectOs: function () {
                var userAgent = $window.navigator.userAgent;
                var os = {mac: /macintosh/i, windows: /windows/i};
                for (var key in os) {
                    if (os[key].test(userAgent)) {
                        return key;
                    }
                }
                return 'unknown';
            },
            detectBrowser: function () {
                var userAgent = $window.navigator.userAgent;
                var browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i};
                for (var key in browsers) {
                    if (browsers[key].test(userAgent)) {
                        return key;
                    }
                }
                return 'unknown';
            },
            isHome: function() {
                return $state.current.name == 'home';
            },
            getTrustedHTML: function(content) {
                if(content !== undefined) {
                    if (content != null) {
                        return $sce.trustAsHtml(content);
                    } else {
                        return $sce.getTtrustedHtml(content);;
                    }
                }
            },
            checkDevice: function() {
                var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                // console.log('isMobile: ', isMobile);
                
                if (isMobile) {
                    // Disable hovers
                    $('body').addClass('mobileDevice');
                } else {

                }
                return isMobile;
                // function screenTest(e) {
                //     if (e.matches) {

                //     } else {

                //     }
                // }
                // var mql = window.matchMedia('(max-width: 600px)');
                // mql.addListener(screenTest);

                // if (window.matchMedia("(min-width: 400px)").matches) {
                //     console.log('/* the viewport is at least 400 pixels wide */');

                // } else {
                //     console.log('/* the viewport is less than 400 pixels wide */');

                // }
            },
            checkWindowWidth: function(limit) {
                return window.matchMedia('(max-width: '+limit+'px)').matches;
            },
            mediaCallback: function(limit, callbackTrue, callbackFalse) {
                var mql = window.matchMedia('(max-width: '+limit+'px)');
                mql.addListener(listener);
                function listener(e) {
                    if (e.matches) {
                        callbackTrue();
                    } else {
                        callbackFalse();
                    }
                }
            }
        }
    }])

    .filter('htmlToPlaintext', [function () {
        return function (text) {
            return angular.element(text).text();
        }
    }])

    .filter('prettyLink', [function () {
        return function (text) {
            if (text.match('http://www.')) {
                var regex = text.replace('http://www.', '');
            } else if (text.match('https://www.')) {
                var regex = text.replace('https://www.', '');
            } else if (text.match('http://')) {
                var regex = text.replace('http://', '');
            } else if (text.match('https://')) {
                var regex = text.replace('https://', '');
            } else if (text.match('www.')) {
                var regex = text.replace('www.', '');
            } else {
                var regex = text;
            }
            return regex;
        }
    }])

    .directive('imageLoader', ['$rootScope', function ($rootScope) {
        return {
            restrict: 'A',
            scope: true,
            link: function (scope, element, attrs) {

                if (angular.isDefined(attrs.condition) && angular.isDefined(attrs.key)) {
                    if (attrs.condition == attrs.key) {
                        // console.log(attrs.condition, attrs.key);
                        element.bind('load', function () {
                            $rootScope.$broadcast('imageLoader', 'loaded');
                        })

                    }
                } else if(angular.isDefined(attrs.page)) {
                    element.bind('load', function () {
                        $rootScope.$broadcast('imageLoader', {'page': attrs.page, 'type': 'imgLoaded'});
                    })
                } else {
                    element.bind('load', function () {
                        $rootScope.$broadcast('imageLoader', {'page': 'home', 'type': 'imgLoaded'});
                    })
                }

            }
        }
    }])
    .factory('debounce', ['$timeout','$q', function($timeout, $q) {
        // The service is actually this function, which we call with the func
        // that should be debounced and how long to wait in between calls
        return function debounce(func, wait, immediate) {
          var timeout;
          // Create a deferred object that will be resolved when we need to
          // actually call the func
          var deferred = $q.defer();
          return function() {
            var context = this, args = arguments;
            var later = function() {
              timeout = null;
              if(!immediate) {
                deferred.resolve(func.apply(context, args));
                deferred = $q.defer();
              }
            };
            var callNow = immediate && !timeout;
            if ( timeout ) {
              $timeout.cancel(timeout);
            }
            timeout = $timeout(later, wait);
            if (callNow) {
              deferred.resolve(func.apply(context,args));
              deferred = $q.defer();
            }
            return deferred.promise;
          };
        };
      }]);
