angular.module('menu', [])
    .directive('menuModule', ['$state', '$rootScope', '$stateParams', '$timeout', 'config', '$sce', 'sessionService', '$translator', 'utilsService', 'wpService',
        function ($state, $rootScope, $stateParams, $timeout, config, $sce, sessionService, $translator, utilsService, wpService) {
            return {
                restrict: 'E',
                templateUrl: 'app/shared/menu/menuView.html',
                transclude: true,
                scope: {
                    'layout': "@",
                    'category': "="
                },
                link: function (scope, element, attrs) {
                    scope.Utils = utilsService;
                    var $ = utilsService.getElementByClass;

                    var menu_container = $('menu__container');
                    var menu_items = $('menu__container_item');
                    var menu_transition = $('menu_transition');
                    var menu_small = $('menu__container_small');

                    var menu_small_link = $('item_small');
                    var menu_transition_small = $('menu_transition_small');

                    scope.langs = config.LANGS;

                    scope.currentLang = sessionService.getLang();
                    scope.currentSlug = $stateParams.category;

                    scope.translate = function (key) {
                        // return angular.lowercase($translator.translate(key));
                        return $translator.translate(key);
                    }

                    scope.switch = function _switch(lng) {
                        if($('menu__details').hasClass('opened')) {
                            $('menu__details').toggleClass('opened');
                            $timeout(function () {
                                goProjectPageAnim();
                                $timeout(function () {
                                    sessionService.setLang(lng);
                                }, 700);
                            }, 600);
                        } else {
                            goProjectPageAnim();
                            $timeout(function () {
                                sessionService.setLang(lng);
                            }, 700);
                        }
                    }

                    scope.detectEvent = function (slug, params) {
                        if(params && params.category) {
                            params.category = $translator.translate(params.category);
                        }

                        if (!($rootScope.currentState == 'category' && slug == 'category' && params.category == $stateParams.category)) {
                            if (!($rootScope.currentState == 'home' && slug == 'home')) {
                                if($('menu__details').hasClass('opened')) {
                                    $('menu__details').toggleClass('opened');
                                    $timeout(function () {
                                        if (($rootScope.currentState == 'project' || $rootScope.currentState == 'ProjectByCategory') && (slug == 'home' ||  slug == 'category')) {
                                            goHomePageAnim();
                                        } else {
                                            goProjectPageAnim();
                                        }
                                        $timeout(function () {
                                            scope.Utils.go(slug, params);
                                        }, 700);
                                    }, 600);
                                } else {
                                    if (($rootScope.currentState == 'project' || $rootScope.currentState == 'ProjectByCategory') && (slug == 'home' ||  slug == 'category')) {
                                        goHomePageAnim();
                                    } else {
                                        goProjectPageAnim();
                                    }
                                    $timeout(function () {
                                        // console.log(slug, params);
                                        scope.Utils.go(slug, params);
                                    }, 700);
                                }
                            }
                        }
                    }

                    function goProjectPageAnim() {
                        menu_items.removeClass('animateFadeIn');
                        $timeout(function () {
                            menu_container.removeClass('animateHeightRetract');
                        })
                    }

                    function goHomePageAnim() {
                        var menu_small_link = $('item_small');
                        menu_small_link.css('opacity', '0');
                        menu_small_link.css('transition', 'opacity .3s ease-out');
                        menu_small.removeClass('animateSmall_height');
                    }

                    function menuHomeRetract() {
                        $timeout(function () {
                            menu_transition.removeClass('hidden');
                            menu_transition.addClass('show');
                            $('mobileDevice').css('overflow', 'hidden');
                            $timeout(function () {
                                menu_transition.removeClass('show');
                                $timeout(function () {
                                    menu_container.addClass('animateHeightRetract');
                                    menu_transition.addClass('hidden');
                                    $timeout(function () {
                                        menu_items.addClass('animateFadeIn');
                                        $('mobileDevice').css('overflow', 'initial');
                                    }, 800)
                                }, 1500)
                            }, 1200)
                        }, 0)
                    }

                    function menuProjectRetracted(forceClosing) {
                        menu_small_link = $('item_small');
                        if (forceClosing) {
                            menu_transition_small.addClass('hidden');
                            menu_transition_small.css('opacity', '0');
                            menu_small_link.css('opacity', '1');
                            menu_transition_small.css('transition', 'none');
                            menu_small.addClass('animateSmall_height');
                            $('mobileDevice').css('overflow', 'initial');
                        } else {
                            // RSW fadeOut
                            $timeout(function () {
                                menu_transition_small.removeClass('hidden');
                                menu_transition_small.addClass('show');
                                $timeout(function () {
                                    menu_transition_small.removeClass('show');
                                    // Black background retract
                                    $timeout(function () {
                                        menu_small.addClass('animateSmall_height');
                                        menu_transition_small.addClass('hidden');

                                        // Menu small FadeIn
                                        $timeout(function () {
                                            menu_small_link.css('opacity', '1');
                                            menu_small_link.css('transition', 'opacity .3s ease-out');
                                            $('mobileDevice').css('overflow', 'initial');
                                        }, 800)
                                    }, 1500)
                                }, 1200)
                            }, 0)
                        }
                    }

                    $rootScope.$on('startAnim', function () {
                        goProjectPageAnim();
                    })

                    scope.toggleMenu = function () {
                        $('menu__details').toggleClass('opened');
                    }

                    function menuHeight() {    
                        var decriptionHeight = $('mobile_description').height();
                        var activityHeight = $('mobile_activity').height();
                        var contactHeight = $('mobile_contact').height();
                        var teamHeight = $('mobile_team').height();
                        var blocHeight = decriptionHeight + activityHeight + contactHeight + teamHeight + 150;
                        if ($('menu__details_item_mobile').height() == 0) {
                            $('menu__details_item_mobile').css('height', blocHeight);
                        } else {
                            $('menu__details_item_mobile').css('height', 0);
                        }
                    }

                    scope.toggleMenuMobile = function () {
                        menuHeight();
                        $('menu__details_item_mobile').toggleClass('open');
                    }

                    scope.teamDisplay = function(btn)  {
                        var contactBtn = $('contact_btn');
                        contactBtn.toggleClass('active');
                        if (btn == 'contactBtn') {
                            if (contactBtn.hasClass('active')) {
                                $('menu_agency').css('display','none');
                                $('menu_team').css('display','block');
                            } else {
                                $timeout(function () { 
                                    $('menu_agency').css('display','block');
                                    $('menu_team').css('display','none');
                                }, 400)
                            }
                        }
                        if (btn == 'agencyBtn' && contactBtn.hasClass('active')) {
                            $('menu_agency').css('display','block');
                            $('menu_team').css('display','none');
                        }
                    }
                    
                    function init() {
                        if (($rootScope.previousState == 'project' && $rootScope.currentState == 'project') || ($rootScope.previousState == 'ProjectByCategory' && $rootScope.currentState == 'ProjectByCategory')) {
                            menuProjectRetracted(true);
                        }
                        scope.project = null;
                        var slug = $stateParams.slug;
                        var currentCategory = $stateParams.category ? $translator.translate('category_' + $stateParams.category) : $translator.translate('category_selected');

                        $(window).resize(function () { 
                            console.log('on resize');
                         })

                        if (scope.layout == 'small' && slug) {
                            wpService.getPost({
                                    'slug': slug
                                }, currentCategory)
                                .then(function (response) {
                                    console.log(response);
                                    scope.project = response;
                                    wpService.getCategories()
                                        .then(function (response) {
                                            console.log(response);                              
                                            scope.categories = response.filter(function (el) {
                                                return scope.project.categories.find(function (projectCat) {
                                                    return (el.id == projectCat && el.slug != $translator.translate('selected'));
                                                });
                                            });
                                            console.log(scope.categories);
                                            
                                            $timeout(function () {
                                                if (($rootScope.previousState == 'project' && $rootScope.currentState == 'project') || ($rootScope.previousState == 'ProjectByCategory' && $rootScope.currentState == 'ProjectByCategory')) {
                                                    menuProjectRetracted(true);
                                                } 
                                                else {
                                                    $timeout(function () {
                                                        menuProjectRetracted();
                                                    }, 1000)
                                                }
                                            });
                                        });
                                }, function (error) {
                                    console.log(error);

                                });
                        } else {
                            menuHomeRetract();
                        }
                        
                    }
                    scope.isHome = function () { 
                        console.log($state.is("home"));
                       return $state.is("home");
                    }

                    init();
                }
            }
        }
    ]);
