angular.module('regularswitch', [

    /******************
     * Angular Services
     */
    'ui.router',
    'ngResource',
    'ngStorage',
    'ngAnimate',
    'ngTranslator',

    /**********
     * Services
     */
    'api.wp',
    'api.wp.auth',
    'service.redirection',
    'service.auth0',
    'service.storage',
    'service.session',
    'service.utils',

    /************
     * Directives
     */
    'loader',
    'menu',
    'footer',


    /*******
     * Pages
     */
    'home',
    'project',
    'errorsPages'

]);
