/*
 * Session Service
 *
 * SessionService is triggered at each full refresh to create the session
 * It requires the Node Api Token to be able to access the api, and stores it
 * Then, if the customer logs in, is will store his data and WP's Token
 *
 */

angular.module('service.session', [])
    .run(['$rootScope', 'sessionService',
        function ($rootScope, sessionService) {

            
            // Event that will update the Session storage
            $rootScope.$on('sessionService:change', function () {
                sessionService.$save();
            });

        }
    ])
    .service('sessionService', ['storeSession', '$state', '$stateParams', '$rootScope', '$q', 'Auth0', 'config', '$resource', 'wpAuthService', '$timeout', '$window', '$translator',
        function (storeSession, $state, $stateParams, $rootScope, $q, Auth0, config, $resource, wpAuthService, $timeout, $window, $translator) {

            var self = this;

            this.init = function() {
                
                // We check if a session has been stored in localStorage, restore it if so, initialise it otherwise
                if (angular.isObject(storeSession.get('session'))) {

                    // A session has been found in localStorage, and is being restored
                    this.$restore(storeSession.get('session'))
                        .then(function () {
                            // TODO - Aymeric:  Adding tests < 30min => popup rester connecté ? -> refresh token
                            self.isValid()
                                .then(function () {
                                }, function (error) {
                                    // Error Popup
                                    $timeout(function () {
                                        $rootScope.$broadcast('eventsHandler:error', {type: 'token', msg: 'expired'});
                                    });
                                    return self.$stopSession();
                                });

                        })


                }
                else {
                    // No session existent in localStorage
                    // A session is initialised and the Node Api Token is made

                    // TODO : here, we can try to find out what the currency is
                    this.initSession();
                    this.initLang();
                    return this.refreshApiToken();
                }
                
                
                
                
            };
            //----------------------//
            //--- API NODE TOKEN ---//
            //----------------------//

            // ***********
            // Token that is delivered by the Node API to ensure the data coming from API is delivered to the frontend
            // website only, and not from the API Endpoint url
            // ***********

            // RefreshApiToken will refresh the token if it's more that 2 hours old, or if the session is new and no
            // token has yet been delivered
            this.refreshApiToken = function () {
                var self = this;
                var deferred = $q.defer();

                // Check if the session is new and doesn't posses yet a token
                if (this.getSession().api_token != '' && this.getSession().api_validity != '') {
                    var apiCheck = new Date;

                    // If a token is already existent, it checks if it's more than 2 hours old
                    if (apiCheck.getTime() > (this.getSession().api_validity - (2 * 60 * 60 * 1000))) {

                        // If the token is more than 2 hours old, it is renewed
                        return Auth0.getAccessToken()
                            .then(function (success) {
                                
                                // The token is stored to the session that is saved
                                self.setApiToken(success);
                                self.$save();
                                // The token is sent back with the promise
                                deferred.resolve(success.access_token);
                                return deferred.promise;
                            }, function(error) {
                            });
                    } else {
                        // If the token is more 2 hours old, no need to refresh it
                        // The token is sent back with the promise
                        deferred.resolve(this.getSession().api_token);
                        return deferred.promise;
                    }
                }

                // If the session is new, it is restored to ensure a clean start, and it requires a token to Auth0
                else {
                    this.$restore;

                    return Auth0.getAccessToken()
                        .then(function (success) {
                            // The token is stored to the session that is saved
                            self.setApiToken(success);
                            self.$save();
                            deferred.resolve(success.access_token);
                            // The token is sent back with the promise
                            return deferred.promise;
                        }, function(error) {
                            console.log(error);
                        });
                }
            };

            // SetApiToken will save the token delivered by Auth0 in the user session
            this.setApiToken = function (apiToken) {
                this.session.api_token = apiToken.access_token;
                var time = new Date();
                this.session.api_validity = time.getTime() + (apiToken.expires_in * 1000);
                // Will expire after the current time + the time needed from Auth0 to expire.
            };

            // GetApiToken returns the token already formated to be sent in the Authorization header
            this.getApiToken = function () {
                return 'Bearer ' + this.getSession().api_token;
            };
            

            this.initLang = function() {
                $translator
                    .configure({
                        language : this.session.lang, //name of en.json 
                        path     : "languages/", //root directory for languages 
                        default  : "fr" //default language 
                    });
            };
            this.getLang = function () {
                return this.getSession().lang;
            };
            this.setLang = function (lang) {
                this.session.lang = lang;
                if ($translator.ready()) {
                    $translator.changeLanguage({ language : lang, path: "languages/", default: "fr" })
                        .then(function() {

                            if($stateParams.category) {
                                var catSlugTrad = $translator.translate('slug_'+$stateParams.category);
                                $stateParams.category = catSlugTrad;
                            }
                              $state.reload();
                        });
                }
                $rootScope.$broadcast('sessionService:change');
            };


            //-------------------------//
            //--- SESSION FUNCTIONS ---//
            //-------------------------//


            // TODO : check browser language
            this.initSession = function () {
                var lang = $window.navigator.language || $window.navigator.userLanguage; 
                if(lang != 'fr') {
                    lang = 'en';
                }
                this.session = {
                    id: '',
                    first_name: '',
                    last_name: '',
                    username: '',
                    lang: lang,
                    
                    api_token: '',
                    api_validity: '',
                    
                    data: {}
                };
        
                return this.session;
            };

            this.startSession = function (session) {
                
                // Here, the local session has already been initialised at run time

                // TODO - Fill the session with the right stuff
                if (angular.isDefined(session.id) && angular.isDefined(session.username) && angular.isDefined(session.token)) {
                    this.session.id = session.id;
                    this.session.username = session.username;
                    this.session.token = session.token;
                    this.session.lang = session.lang;

                    var time = new Date();
                    this.session.validity = time.getTime();

                    // The broadcast will save the session in LocalStorage
                    $rootScope.$broadcast('sessionService:change');
                    return this.getSession();
                }
                else {
                    return {'error': 'Missing ID, USERNAME or TOKEN'};
                }
            };

            // ***********
            // Session Validity
            // ----------------
            // isValid verify if the session is still valid.
            // It verifies if the NODE API TOKEN is valid (and refreshes it if needed)
            // And verifies if the WordPress session is valid (if such session exists = customer has logged in)
            // ***********

            this.isValid = function () {
                var deferred = $q.defer();
                var self = this;

                
                // First, let's check the Auth0 token validity and refresh it if needed
                return this.refreshApiToken()
                    .then(function (success) {
                        deferred.resolve(success);
                        
                        // // There is a Customer's session
                        // if (self.getSession().token != '') {
                        //     console.log('SUCCESS: ', success);

                        //     // WP authentication
                        //     return wpAuthService.checkAuth(self.getSession().token, self.getApiToken())
                        //         .then(function (success) {
                        //             if (success.data.status == 403) { // Unauthorized
                        //                 deferred.reject('403');
                        //                 return deferred.promise;
                        //             } else {
                        //                 var now = new Date();
                        //                 var diff = now.getTime() - self.getSession().validity;
                        //                 console.log('OK NOUVELLE DATE', self.getSession());

                        //                 // 2 hours validity in miliseconds 2h * 60min * 60sec *1000ms
                        //                 if (diff < (1000 * 60 * 60 * 2)) {
                        //                     deferred.resolve(diff);
                        //                 } else {
                        //                     deferred.reject(diff);
                        //                 }
                        //                 return deferred.promise;
                        //             }
                        //         });

                        // } else {
                        //     deferred.resolve(true);
                        //     return deferred.promise;
                        // }
                    }, function (error) {
                        console.log('ERREUR: ', error);
                        
                        deferred.reject(error);
                        return deferred.promise;
                    });
            };

            // Not used, as there's no way yet to update the WP token if expired
            // TODO - Aymeric : Make a way to tell the customer to log in again if session has expired
            this.updateToken = function (token) {
                this.session.token = token;
                this.session.validity = new Date();
                $rootScope.$broadcast('sessionService:change');
            };

            this.getSession = function () {
                return this.session;
            };

            this.getUsername = function () {
                return this.getSession().username;
            };


            //------------------------------//
            //--- SESSION CORE FUNCTIONS ---//
            //------------------------------//

            this.$stopSession = function () {
                
                // The session is destroyed and emptied and then the token is remade in order to access the website
                $rootScope.$broadcast('sessionService:change', {});
                storeSession.remove('session');
                this.initSession();
                this.initLang();
                return this.refreshApiToken();
            };

            // $restore restores the session (new or not)
            this.$restore = function (session) {
                var deferred = $q.defer();
                var _self = this;

                _self.initSession();
                _self.session.id = session.id;
                _self.session.username = session.username;
                _self.session.token = session.token;
                _self.session.validity = session.validity;
                _self.session.api_token = session.api_token;
                _self.session.api_validity = session.api_validity;
                _self.session.api_validity = session.api_validity;
                _self.session.lang = session.lang;
                
                this.initLang();

                _self.$save();

                deferred.resolve(_self.getSession());
                return deferred.promise;
            };

            // $save saves the session in the localStorage
            this.$save = function () {
                return storeSession.set('session', this.getSession());
            };

        }
    ])

    .service('storeSession', ['$window',
        function ($window) {

            // ***********
            // storeSession only stores a given session to localStorage
            // And retrieves it with ots key
            // ***********

            return {

                get: function (key) {
                    if ($window.sessionStorage[key]) {
                        var session = angular.fromJson($window.sessionStorage[key]);
                        return session;
                    }
                    return false;
                },

                set: function (key, val) {
                    if (val === undefined) {
                        $window.sessionStorage.removeItem(key);
                    } else {
                        $window.sessionStorage[key] = angular.toJson(val);
                    }
                    return $window.sessionStorage[key];
                },

                remove: function (key) {
                    $window.sessionStorage.removeItem(key);
                }
            }
        }
    ]);
