angular.module('project', [])
    .controller('projectController', ['$scope', '$rootScope', '$window', '$stateParams', '$translator', '$sce', '$timeout', 'sessionService', 'wpService', 'utilsService',
        function ($scope, $rootScope, $window, $stateParams, $translator, $sce, $timeout, sessionService, wpService, utilsService) {

            $scope.Utils = utilsService;
            $scope.project = null;
            $scope.currentLang = sessionService.getLang();
            var currentProjectIndex;
            var nextProject,
                prevProject;

            $scope.previousProject = function () {
                var projectInfoContainer = utilsService.getElementByClass('project__infos_content');
                var projectGallery = utilsService.getElementByClass('project__gallery');

                var projectTransition = utilsService.getElementByClass('project__transition');

                projectTransition.addClass('goPrev');
                $('.mobileDevice').css('overflow', 'hidden');
                $timeout(function () {
                    $scope.project = prevProject;
                    $('.mCSB_container').css('top', 0);
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                    $timeout(function () {
                        projectTransition.addClass('leavePrev');
                        projectTransition.removeClass('goPrev');
                        $timeout(function () {
                            projectTransition.removeClass('leavePrev');
                            $timeout(function () {
                                if ($stateParams.category) {
                                    utilsService.go('ProjectByCategory', {
                                        'category': $stateParams.category,
                                        'slug': prevProject.slug
                                    });
                                } else {
                                    utilsService.go('project', {
                                        'slug': prevProject.slug
                                    });
                                }
                                projectTransition.removeClass('leavePrev');
                                $('.mobileDevice').css('overflow', 'initial');
                            }, 500);
                        }, 1000);
                    }, 500);
                }, 500);
                // projectInfoContainer.addClass('leavingTop');
                // projectGallery.addClass('leavingBottom');
                // console.log(utilsService.getElementByClass('project__infos_content'));
                // $timeout(function() {
                //     // if($stateParams.category) {
                //     //     utilsService.go('ProjectByCategory', {'category': $stateParams.category,'slug': prevProject.slug});
                //     // } else {
                //     //     utilsService.go('project', {'slug': prevProject.slug});
                //     // }
                //     $scope.project = prevProject;
                // }, 600);
            }

            $scope.nextProject = function () {

                var projectInfoContainer = utilsService.getElementByClass('project__infos_content');
                var projectGallery = utilsService.getElementByClass('project__gallery');

                var projectTransition = utilsService.getElementByClass('project__transition');

                projectTransition.addClass('goNext');
                $('.mobileDevice').css('overflow', 'hidden');
                $timeout(function () {
                    $('.mCSB_container').css('top', 0);
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    $scope.project = nextProject;
                    $timeout(function () {
                        projectTransition.addClass('leaveNext');
                        projectTransition.removeClass('goNext');
                        $timeout(function () {
                            projectTransition.removeClass('leaveNext');
                            $timeout(function () {
                                if ($stateParams.category) {
                                    utilsService.go('ProjectByCategory', {
                                        'category': $stateParams.category,
                                        'slug': nextProject.slug
                                    });
                                } else {
                                    utilsService.go('project', {
                                        'slug': nextProject.slug
                                    });
                                }
                                projectTransition.removeClass('leaveNext');
                                $('.mobileDevice').css('overflow', 'initial');
                            }, 500);
                        }, 1000);
                    }, 200);
                }, 500);
            }

            function init() {
                $scope.Utils.checkDevice();

                var imgLoaded = 0;
                var slug = $stateParams.slug;
                var currentCategory = $stateParams.category ? $translator.translate('category_' + $stateParams.category) : $translator.translate('category_selected');

                $scope.project = null;
                wpService.getPost({
                        'slug': slug
                    }, currentCategory)
                    .then(function (response) {
                        $scope.project = response;
                        // console.log($scope.project);
                        // console.log($scope.project.acf.image_gallery);
                        if (!$scope.Utils.checkWindowWidth(767) && !$scope.Utils.checkDevice()) {
                            $(".wrapper.project").mCustomScrollbar({
                                autoHideScrollbar: true,
                                documentTouchScroll: true,
                                scrollInertia: 50,
                                documentTouchScroll: true

                            });
                        }

                        $scope.Utils.mediaCallback(767, function () {
                            if (!$scope.Utils.checkDevice()) {
                                // $(".wrapper.project").mCustomScrollbar("disable");
                                $(".wrapper.project").mCustomScrollbar("destroy");
                            }
                        }, function () {
                            if (!$scope.Utils.checkDevice()) {
                                // $(".wrapper.project").mCustomScrollbar("update");
                                $(".wrapper.project").mCustomScrollbar({
                                    autoHideScrollbar: true,
                                    documentTouchScroll: true,
                                    scrollInertia: 50,
                                    documentTouchScroll: true

                                });
                            }
                        })
                        wpService.getPosts()
                            .then(function (success) {
                                // console.log(currentCategory);

                                currentProjectIndex = success[currentCategory].indexOf(success[currentCategory].find(function (el) {
                                    return $scope.project.id == el.id;
                                }));
                                if (currentProjectIndex > 0 && currentProjectIndex < success[currentCategory].length - 1) {
                                    nextProject = success[currentCategory][currentProjectIndex + 1];
                                    prevProject = success[currentCategory][currentProjectIndex - 1];
                                } else if (currentProjectIndex == 0) {
                                    nextProject = success[currentCategory][currentProjectIndex + 1];
                                    prevProject = success[currentCategory][success[currentCategory].length - 1];
                                } else if (currentProjectIndex == success[currentCategory].length - 1) {
                                    nextProject = success[currentCategory][0];
                                    prevProject = success[currentCategory][currentProjectIndex - 1];
                                }
                                $timeout(function () {
                                    var count = 0;
                                    var length = $('.project__gallery_cover').length;
                                    angular.forEach($('.project__gallery_cover'), function(value) {
                                        if(value.complete) {
                                            count++;
                                        }
                                    })
                                    if(count >= length) {
                                        $scope.prevPost = prevProject;
                                        $scope.nextPost = nextProject;
                                    }
                                    $('.project__gallery_cover').bind('load', function () {
                                        imgLoaded++;
                                        if (imgLoaded == $scope.project.acf.image_gallery.length) {
                                            $timeout(function () {
                                                $scope.prevPost = prevProject;
                                                $scope.nextPost = nextProject;                                                
                                            });
                                        }
                                    });
                                },0);
                            });
                    });
            };
            init();
        }
    ]);
