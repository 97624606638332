/**
 * Created by RSW on 18/08/2016.
 */
angular.module('regularswitch')
    .config(['$stateProvider', '$urlRouterProvider', '$httpProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider) {
            $urlRouterProvider.otherwise('/');

            $stateProvider
           
                .state('home', {
                    // parent: 'root',
                    url: '/',
                    templateUrl: 'app/components/home/homeView.html',
                    controller: 'homeController',
                    resolve: {
                        session: function ($stateParams, sessionService) {
                            return sessionService.init();
                        }
                    }
                })

                .state('category', {
                    // parent: 'root',
                    url: '/creative-agency/:category',
                    templateUrl: 'app/components/home/homeView.html',
                    controller: 'homeController',
                    resolve: {
                        session: function ($stateParams, sessionService) {
                            return sessionService.init();
                        }
                    }
                })

                .state('ProjectByCategory', {
                    // parent: 'root',
                    url: '/creative-agency/:category/:slug',
                    templateUrl: 'app/components/project/projectView.html',
                    controller: 'projectController',
                    resolve: {
                        session: function ($stateParams, sessionService) {
                            return sessionService.init();
                        }
                    }
                })

                .state('project', {
                    // parent: 'root',
                    url: '/:slug',
                    templateUrl: 'app/components/project/projectView.html',
                    controller: 'projectController',
                    resolve: {
                        session: function ($stateParams, sessionService) {
                            return sessionService.init();
                        }
                    }
                })
                .state('404', {
                    // parent: 'root',
                    url: '/404',
                    templateUrl: 'app/components/errors/404.html',
                    controller: 'errorsPagesController',
                    resolve: {
                        session: function ($stateParams, sessionService) {
                            return sessionService.init();
                        }
                    }
                });
        }
    ])
    // .config(['$locationProvider', function ($locationProvider) {
    //     $locationProvider.html5Mode(true).hashPrefix('!');
    // }])
    .constant('config', {

        // Local
        // URL_WP: 'http://localhost/rsw-site',
        // URL_API: 'http://localhost:5000/api/rsw',
        // URL_API: 'http://localhost:5000/api/7c',

        // Pour se connecter au server depuis le local
        // URL_WP: 'https://test.regularswitch.com/rsw/backend',
        // URL_API: 'https://test.regularswitch.com/api/rsw',
        
        /**********
         * LIVE API
         ----------
         */
         URL_API: 'https://regularswitch.herokuapp.com/api/7c',

        // Production
        // URL_WP: 'https://test.regularswitch.com/rsw/backend',
        // URL_API: '/api/rsw',
        // URL_WP: 'http://www.regularswitch.com/backend',
        // URL_API: '/api/rsw',

        WP: '/wp-json/wp/v2',

        WP_POSTS: '/posts',
        WP_PAGES: '/pages',
        WP_CATEGORIES: '/categories',
        WP_MEDIA: '/media',

        WP_API: '/wp',

        //RSW
        // LANGS: ['en', 'pt-br', 'fr'],
        // DEFAULT_LANG: 'en'

        //7C
        LANGS: ['en', 'fr'], 
        DEFAULT_LANG: 'fr'

    })
    .run(['$rootScope', function ($rootScope) { 
        angular.lowercase=function(text){
            return text.toLowerCase();
        }
        $rootScope.$on('$stateChangeSuccess', function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        });
        $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from.name;
            $rootScope.currentState = to.name;
        });
    }]);
